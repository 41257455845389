<template>
  <div class="columns">
    <div class="column is-6-tablet is-4-widescreen is-offset-3-tablet is-offset-4-widescreen">
      <div class="card" style="overflow: visible">
        <div class="card-content">
          <form @submit.prevent="onLogin" autocomplete="off">
            <div class="has-text-centered" v-if="false">
              <figure class="image is-128x128 mx-auto">
                <img src="@/assets/logo.svg" alt="Logo Alfalab">
              </figure>
            </div>

            <b-field label="Servidor">
              <b-select @input="onSelectServer"
                        :required="true"
                        :expanded="true" :value="server">
                <option :key="i.code" v-for="i in servers" :value="i.code">{{i.url}}</option>
              </b-select>
            </b-field>

            <b-field
                :type="errors.username?'is-danger':''"
                :label="$t('common.attributes.username')"
                :message="errors.username"
            >
              <b-input
                  v-model="data.username"
                  @input="onSearchBranchOffice"
                  placeholder="Clave de usuario"
                  :loading="loading"
                  autofocus
              ></b-input>
            </b-field>

            <b-field
                :type="errors.password?'is-danger':''"
                :label="$t('common.attributes.password')"
                :message="errors.password"
            >
              <b-input
                  v-model="data.password"
                  type="password"
                  password-reveal
                  :placeholder="$t('common.placeholders.password')"
              ></b-input>
            </b-field>
            <select-branch-office v-model="data.branch_office"
                                  :data="branchOffices"
                                  :loading="loading"
                                  :searchable="branchOffices.length > 4"
                                  :disabled="! data.username">
            </select-branch-office>
            <!--            <b-field>-->
            <!--              <b-checkbox v-model="data.rememberMe">{{ $t('auth.remember') }}</b-checkbox>-->
            <!--            </b-field>-->

            <div class="field">
              <button class="button is-primary is-rounded is-fullwidth"
                      :disabled="! canLogin">
                {{ $t('auth.login.action') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="sub_div">
      <p>Versión: <span v-html="this.$alfalab.version()"></span></p>
      <a
          class="version-link"
          onclick="window.location.reload(true);"
          v-if="version !== $alfalab.version()"
          href="#">Nueva versión disponible : {{version}}.
        <br>Click para actualizar </a>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import SelectBranchOffice from "../../components/brach-office/SelectBranchOffice";

export default {
  name: "Login",
  components: {SelectBranchOffice},
  data() {
    return {
      data: {
        rememberMe: false,
        branch_office: 0,
      },
      loading: false,
      branchOffices: [],
      errors: {},
      in_process: false,
      recaptcha_enabled: false,
      grecaptcha: null,
      version: '',
      server: ''
    };
  },
  computed: {
    canLogin() {
      return !!this.data.username && !!this.data.password && !!this.data.branch_office && !!this.server;
    },
    servers() {
      return this.$store.getters.servers;
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  mounted() {
    //this.$loader.show();
    this.setServers().then(() => {
      this.$http.get('/recaptcha').then(async ({data}) => {
        this.recaptcha_enabled = data.status;
        if(data.status){
          const tokenRecaptcha = process.env.VUE_APP_RECAPTCHA_TOKEN;
          this.grecaptcha = await this.loadRecaptcha(tokenRecaptcha);
        }
        this.version = data.version;
      }).finally(() => {
        this.$loader.hide();
      })
    });
    /*
    */
  },
  methods: {
    async onLogin() {
      if (!this.in_process) {
        this.$loader.show();
        this.in_process = true;
        this.errors = {};
        await this.$http.get('../sanctum/csrf-cookie');
        if (this.recaptcha_enabled) {
          const tokenRecaptcha = process.env.VUE_APP_RECAPTCHA_TOKEN;
          this.grecaptcha.ready(() => {
            this.grecaptcha.execute(tokenRecaptcha, {action: 'submit'}).then((token) => {
              this.$http.post('verify-recaptcha', {
                token
              }).then(({data}) => {
                if (data.status) {
                  this.runLogin();
                } else {
                  this.$loader.hide();
                  this.in_process = false;
                  this.$toast.error(data.message);
                }
              }).catch(e => {
                this.$loader.hide();
                this.in_process = false;
                console.error(e);
              })
            });
          });
        } else {
          this.runLogin();
        }
      }
    },
    runLogin() {
      this.$auth.login({
        data: this.data,
        success({data}) {
          this.$loader.hide();
          this.in_process = false;
          this.$toast.success(data.message);
          this.$store.commit('setMainData', data.meta);
          this.$store.commit('setLoggedIn', true);
          this.$store.commit('order/setMedical', data.meta.medical)
          this.$store.dispatch('fetchSpecialities');
          this.getCatalogs();
          //this.getSpecialOffers();
          //console.log(this.$store.state.order.medical);
        },
        error({response = {}}) {
          this.$loader.hide();
          let data = response.data || {};
          this.errors = data.errors || {};
          this.$toast.error(data.message);
          this.in_process = false;
        }
      });
    },
    onSearchBranchOffice: debounce(function (val) {
      if (val === '') {
        return
      }
      this.data.branch_office = null;
      this.errors = {};
      this.loading = true;
      this.$http.get(`auth/branch-office/${val}`)
          .then(({data}) => {
            this.loading = false;
            this.branchOffices = data.data || [];
            if (this.branchOffices.length > 1) {
              this.data.branch_office = this.branchOffices.find(el => el.suggest === true).id;

            } else {
              this.data.branch_office = this.branchOffices[0].id;
            }
          })
          .catch(({response}) => {
            this.loading = false;
            let data = response.data || {};
            this.errors.username = data.message;
          });
    }, 1000),
    getCatalogs() {
      this.$http.get('/sat-regimenes').then(({data}) => {
        this.$store.commit('setSatRegimenes', data.data);
      })
    },
    getSpecialOffers() {
      this.$http.get('/special-promo').then(({data}) => {
        this.$store.commit('setSpecialPromo', data.data);
      })
    },
    loadRecaptcha(siteKey) {
      return new Promise((resolve, reject) => {
        if (document.getElementById('recaptcha-script')) {
          // Si el script ya está cargado, resuélvelo inmediatamente.
          return resolve(window.grecaptcha);
        }

        const script = document.createElement('script');
        script.id = 'recaptcha-script';
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          resolve(window.grecaptcha); // Resuelve la promesa cuando el script se carga.
        };
        script.onerror = () => {
          reject(new Error('No se pudo cargar el script de reCAPTCHA'));
        };

        document.head.appendChild(script);
      });
    },
    async setServers(){
      this.server = this.$store.state.main.apiurl.code || '';
      if(this.server) {
        const server = this.servers.find(item => {
          return item.code === this.server;
        })
        this.$loader.show();
        if (server) {
          this.axios.defaults.baseURL = server.url;
          await this.$http.get('../sanctum/csrf-cookie')
        }
        this.$loader.hide();
      }else{
        const server = this.getDefaultServer();
        this.onSelectServer(server);
      }
      return Promise.resolve();
    },
    async onSelectServer(value){
      this.$loader.show();
      this.server = value;
      const server = this.servers.find(item => {
        return item.code === value;
      })
      this.$store.dispatch('setApiurl', server);
      this.axios.defaults.baseURL = server.url;
      await this.$http.get('../sanctum/csrf-cookie').then(() => {
        const script = document.querySelector('#recaptcha-script');
        this.$http.get('/recaptcha').then(async ({data}) => {
          if(!script && data.status){
            this.recaptcha_enabled = data.status;
            if(data.status){
              const tokenRecaptcha = process.env.VUE_APP_RECAPTCHA_TOKEN;
              this.grecaptcha = await this.loadRecaptcha(tokenRecaptcha);
            }
          }
          this.version = data.version;
        }).finally(() => {
          this.$loader.hide();
        })
      })
      this.$loader.hide();
    },
    getDefaultServer(){
      let server = '';
      if(window.location.toString().startsWith('http://recepcion.lapi.site')){
        server = 'local'
      }
      if(window.location.toString().startsWith('https://alfalab.lapi.mx') ){
        server = 'gateway2';
      }
      if(window.location.toString().startsWith('https://beta.recepcion.lapi.mx') ){
        server = 'gateway'
      }
      if(window.location.toString().startsWith('https://testing.recepcion.lapi.mx')){
        server = 'testing'
      }
      return server;
    }
  }
}
</script>

<style scoped>
.sub_div {
  position: fixed;
  bottom: 5px;
  right: 100px;
}

.version-link {
  color: orangered !important;
}

.version-link:hover {
  opacity: .7;
}
</style>
