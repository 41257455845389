<template>
  <div>
    <main-side-bar-compressed></main-side-bar-compressed>
    <main-side-bar
    ></main-side-bar>
    <div
        :class="`plex-wrapper ${activeMenu ? 'is-expanded' : ''}`"
    >
      <div class="wrapper-inner" id="main-wrapper">
        <!--Static Navbar-->
        <main-nav-bar
        ></main-nav-bar>
        <!--Content-->
        <div class="dashboard-content">
          <router-view></router-view>
        </div>
        <div class="sub_div">
          <p>Versión: <span v-html="this.$alfalab.version()"></span></p>
          <a
              onclick="window.location.reload(true);"
              v-if="$auth.user().version !== $alfalab.version()"
              href="#">Nueva versión disponible : {{$auth.user().version}}.
            <br>Click para actualizar </a>
        </div>
      </div>
    </div>
    <app-menu></app-menu>
  </div>
</template>

<script>
import MainSideBar from "./MainSideBar";
import MainNavBar from "./MainNavBar";
import MainSideBarCompressed from "./sidebar/MainSideBarCompressed";
import AppMenu from "./layout/AppMenu";
import UpdatePasswordModal from "./modals/UpdatePasswordModal.vue";

export default {
  name: "DefaultLayout",
  components: {AppMenu, MainSideBarCompressed, MainNavBar, MainSideBar},
  computed: {
    activeMenu() {
      return this.$store.state.utils.compressMenu;
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('deactivateMenu');
    next();
  },
  mounted() {
    this.$alfalab.checkVersion(this.$auth.user().version, this.$toast);
    const user = this.$auth.user();

    const server = this.getDefaultServer();

    if(server && this.axios.defaults.baseURL !== server) {
      this.axios.defaults.baseURL = server;
      this.$http.get('../sanctum/csrf-cookie');
    }

    if (user.update_password) {
      this.$buefy.modal.open({
        parent: this,
        component: UpdatePasswordModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false
      });
    }

    if (user && !this.$alfalab.enableForeignWorkUI(user)) {
      console.log('Mode: BranchOffice');
      window.onbeforeunload = function (event) {
        event.returnValue = "You have attempted to leave this page. Are you sure?";
      };
    } else {
      console.log('Mode: ForeignWork');
    }

    if (user && user.data_branch_office.id != this.$store.state.main.activeBranchOffice.id) {
      const oldData = this.$store.state.main.activeBranchOffice
      this.$store.commit('setMainData', {...oldData, activeBranchOffice: user.data_branch_office});
      window.location.reload();
    }
  },
  methods: {
    getDefaultServer(){
      let server = this.$store.state.main.apiurl.url || '';
      console.log('server', server)
      if(!server){
        if(window.location.toString().startsWith('http://recepcion.lapi.site')){
          server = 'http://api-gateway.lapi.site/v1'
        }
        if(window.location.toString().startsWith('https://alfalab.lapi.mx') ){
          server = 'https://gateway2.lapi.mx/v1';
        }
        if(window.location.toString().startsWith('https://beta.recepcion.lapi.mx') ){
          server = 'https://gateway.lapi.mx/v1'
        }
        if(window.location.toString().startsWith('https://testing.recepcion.lapi.mx')){
          server = 'https://api.lapi.mx/v1'
        }
      }
      return server;
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null;
  }
}
</script>

<style scoped>
.sub_div {
  position: fixed;
  bottom: 5px;
  right: 20px;
}
</style>
